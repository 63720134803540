import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// Display Components
import AddFamilyMemberConfirmDetailsForm from 'display-components/add-family-member/confirm-details-form';
import {
    FamilyAccountAddMemberAlreadyMemberErrorModalContent,
    FamilyAccountAddMemberGenericErrorModalContent,
    FamilyAccountAddMemberInvitationErrorModalContent,
    FamilyAccountAddMemberInvitationSentModalContent,
    FamilyAccountSuccessModalContent
} from 'display-components/add-family-member/modals';
import AddFamilyMemberSendInvitation from 'display-components/add-family-member/send-invitation';

// Components
import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';

// States
import { accountFetchProfileRoutine } from 'state/account/account.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    familyProfileAddDependentRoutine,
    familyProfileSendDependentInvitationRoutine
} from 'state/family-profile/family-profile.routines';
import { familyProfileDependentToAddSelector } from 'state/family-profile/family-profile.selectors';

// Constants and options
import { getAge } from 'const/options';

// Interfaces and types
import { FamilyAccountAddDependentResponse } from 'types/family-management';

// Utils
import storageHelper from 'util/storageHelper';

// Styles
import './add-minor-confirm-or-send-invitation-flow.style.scss';

// Interface for Dependent Treated Data.
interface DependentDataTreated {
    firstName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    prescriptionNumber: string;
    insuranceID: string | null;
    accountType: number;
    email: string | null;
    phone: string | null;
}

// Main component
const AddMinorConfirmOrSendInvitationFlow = (): ReactElement => {
    // General
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultBackUrl = '/secure/profile/family-account/add-a-minor';
    const successRedirectUrl = '/secure/profile/family-account/';
    const cookies = storageHelper.cookies;

    // Selectors
    const dependentToAddSelector = useSelector(familyProfileDependentToAddSelector);
    const profileObject = useSelector(accountProfileSelector);

    // States
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dependentData, setDependentData] = useState<DependentDataTreated | null>(null);
    const [epostPatientNum, setEpostPatientNum] = useState<string>('');
    const [sendInvitation, setSendInvitation] = useState<boolean>(false);

    // Get data from selector and treat it.
    useEffect(() => {
        if (!dependentToAddSelector || !dependentToAddSelector.FirstName) {
            navigate(defaultBackUrl);
        }

        if (dependentToAddSelector) {
            const dob = new Date(dependentToAddSelector.DateOfBirth);
            const dobMonthFormatted = `0${dob.getMonth() + 1}`.slice(-2);

            setIsLoading(false);
            setSendInvitation(getAge(dob) >= 13);
            setDependentData({
                firstName: dependentToAddSelector?.FirstName,
                lastName: dependentToAddSelector?.LastName,
                age: getAge(dob).toString(),
                dateOfBirth: `${dobMonthFormatted}/${dob.getDate()}/${dob.getFullYear()}`,
                prescriptionNumber: dependentToAddSelector?.PrescriptionNumber,
                insuranceID: getAge(dob) < 13 ? dependentToAddSelector?.InsuranceMemberId : null,
                accountType: dependentToAddSelector?.AccountType,
                email: dependentToAddSelector?.Email,
                phone: dependentToAddSelector?.PhoneNumber
            });
        }
    }, [dependentToAddSelector]);

    // Get profile ePostPatientNum.
    useEffect(() => {
        if (profileObject && profileObject.epostPatientNum) {
            setEpostPatientNum(profileObject.epostPatientNum);
        }
    }, [profileObject]);

    // Show success modal after finish adding the dependent Minor < 13.
    const showSuccessModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    onClose: () => {
                        setIsBusy(false);
                        navigate(successRedirectUrl);
                        dispatch(closeModal({}));
                    },
                    headerContent: (
                        <BirdiModalHeaderWithBackgroundColor
                            headerText={t('pages.profile.addFamilyMember.successModal')}
                            type="info"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountSuccessModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                navigate(successRedirectUrl);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show success modal after finish adding the dependent Minor > 13.
    const showInvitationSentSuccessModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    onClose: () => {
                        setIsBusy(false);
                        navigate(successRedirectUrl);
                        dispatch(closeModal({}));
                    },
                    headerContent: (
                        <BirdiModalHeaderWithBackgroundColor
                            headerText={t('pages.profile.addFamilyMember.invitationSentModal')}
                            type="success"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberInvitationSentModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                navigate(successRedirectUrl);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show error modal after failing on send confirmation to the dependent.
    const showInvitationSentFailureModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => {
                        setIsBusy(false);
                        navigate(defaultBackUrl);
                        dispatch(closeModal({}));
                    },
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberInvitationErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                navigate(defaultBackUrl);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show generic error modal for any other failure than member already added to another account.
    const showGenericErrorModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberGenericErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show error modal when use is already add to another account.
    const showDependentAlreadyAddedErrorModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberAlreadyMemberErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Add minor after clicking on submit button.
    const handleOnSubmit = (contactMethod?: string) => {
        if (dependentData) {
            setIsBusy(true);
            dispatch(
                familyProfileAddDependentRoutine.trigger({
                    dependentData: {
                        FirstName: dependentData.firstName,
                        LastName: dependentData.lastName,
                        DateOfBirth: dependentData.dateOfBirth,
                        PrescriptionNumber: dependentData.prescriptionNumber,
                        EPostPatientNumber: epostPatientNum,
                        AccountType: 'Child'
                    },
                    onSuccess: (response: FamilyAccountAddDependentResponse) => {
                        const addedDependentData = response.Data;
                        dispatch(accountFetchProfileRoutine.trigger());

                        if (sendInvitation) {
                            dispatch(
                                familyProfileSendDependentInvitationRoutine.trigger({
                                    data: {
                                        CommunicationType: contactMethod,
                                        EpostPatientNumber: addedDependentData.EPostPatientNum,
                                        CareGiverDependentId: addedDependentData.ID
                                    },
                                    onSuccess: (response: FamilyAccountAddDependentResponse) => {
                                        showInvitationSentSuccessModal(addedDependentData.FirstName);
                                        /**
                                         * Ticket: DRX-2668
                                         * This cookie is needed to remember the communication method used
                                         * during the send invitation on add dependent flow.
                                         */
                                        cookies.setDependentSendInvitationMethodCookie(
                                            epostPatientNum,
                                            addedDependentData.ID,
                                            addedDependentData.EPostPatientNum,
                                            contactMethod as string
                                        );
                                    },
                                    onFailure: (errors: FamilyAccountAddDependentResponse['Errors']) => {
                                        showInvitationSentFailureModal(addedDependentData.FirstName);
                                    }
                                })
                            );
                        } else {
                            showSuccessModal(dependentData.firstName);
                        }
                    },
                    onFailure: (errors: FamilyAccountAddDependentResponse['Errors']) => {
                        if (errors[0].search('has already been added') >= 0) {
                            showDependentAlreadyAddedErrorModal(dependentData.firstName);
                        } else {
                            showGenericErrorModal(dependentData.firstName);
                        }
                    }
                })
            );
        }
    };

    // On cancel, go back to dependent listing page.
    const handleOnCancel = () => {
        navigate(defaultBackUrl, { state: dependentToAddSelector ?? undefined });
    };

    return (
        <div className="add-minor-confirm-or-send-invitation-flow__container">
            {isLoading ? (
                <div className="loading">{t('pages.profile.addFamilyMember.loadingText')}</div>
            ) : sendInvitation ? (
                <AddFamilyMemberSendInvitation
                    dependentData={dependentData as DependentDataTreated}
                    isBusy={isBusy}
                    onSubmit={(contactMethod) => handleOnSubmit(contactMethod)}
                    onCancel={handleOnCancel}
                />
            ) : (
                <AddFamilyMemberConfirmDetailsForm
                    dependentData={dependentData as DependentDataTreated}
                    isBusy={isBusy}
                    onSubmit={handleOnSubmit}
                    onCancel={handleOnCancel}
                />
            )}
        </div>
    );
};
export default AddMinorConfirmOrSendInvitationFlow;
